<template>
  <div>
    <!-- Footer -->
    <footer class="footer bg-dark py-5">
      <div class="container grid grid-3">
        <div>
          <h1>Euro 2020</h1>
          <p>Copyright &copy; 2020</p>
        </div>
        <nav>
          <ul>
            <li><a :href="`/${i18n.locale}`">{{ $t("nav.recom") }}</a></li>
            <li><a :href="`/${i18n.locale}/statistics`">{{ $t("nav.statistics") }}</a></li>
            <li><a :href="`/${i18n.locale}/experience`">{{ $t("nav.experience") }}</a></li>
            <li><a href="/whatsapp">Whatsapp</a></li>
          </ul>
        </nav>
        <div class="social">
          <a href="#"><i class="fab fa-github fa-2x"></i></a>
          <a href="#"><i class="fab fa-facebook fa-2x"></i></a>
          <a href="#"><i class="fab fa-instagram fa-2x"></i></a>
          <a href="#"><i class="fab fa-twitter fa-2x"></i></a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import i18n from "../i18n";

export default {
  name: "Footer",
  data() {
    return {
       i18n}
  }
};
</script>

<style>
</style>